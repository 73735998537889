
import {Component, Ref, Vue} from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditButtons from "@/components/AuditButtons/index.vue";
import {AuditBodyDto} from "@/utils/customDto";
import {
  ExternalMonthlyPlanDto,
  OrganizationUnitDto,
  ExternalProjectDetailDto,
  AttachmentHostType,
  AuditFlowScope,
  ProjectSpecificGoalDto,
  ExternalProjectSpecificPlanDto
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import ProvinceCityAreaContent from "@/components/CustomForm/TableDetail/components/ProvinceCityAreaContent.vue";

@Component({
  name: "ExternalMonthlyPlanDetail",
  components: {
    ProvinceCityAreaContent,
    AttachmentsView,
    ExportWord,
    AuditButtons
  },

  filters: {
    getSpecificGoal(goalId: string, list: ProjectSpecificGoalDto[]) {
      if (goalId == "") {
        return "其他";
      } else {
        let effect: string | undefined = "";
        if (list && list.length > 0) {
          list.map((effectItem: ProjectSpecificGoalDto) => {
            if (effectItem.id == goalId) {
              effect = effectItem.effect;
              return;
            }
          });
        }
        return effect;
      }
    },

    getSpecificPlan(planId: string, list: ExternalProjectSpecificPlanDto[]) {
      if (planId == "") {
        return "其他";
      } else {
        let effect: string | undefined = "";
        if (list && list.length > 0) {
          list.map((effectItem: ExternalProjectSpecificPlanDto) => {
            if (effectItem.id == planId) {
              effect = effectItem.title;
              return;
            }
          });
        }
        return effect;
      }
    }
  }
})
export default class ExternalMonthlyPlanDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  detailId?: number;
  detail: ExternalMonthlyPlanDto = {
    project: {}
  };
  unitList?: OrganizationUnitDto[] = [];
  selector: string = "table-detail";
  filename: string = "项目月计划表";
  styles: string = "";
  effects?: ProjectSpecificGoalDto[];
  specificPlans?: ExternalProjectSpecificPlanDto[];
  externalProject: ExternalProjectDetailDto = {
    effects: this.effects,
    specificPlans: this.specificPlans
  };
  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.ExternalMonthlyPlan,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  };

  get hostTypeFile() {
    return AttachmentHostType.Internal_Project_Monthly_Summarize_Attachment;
  }

  created() {
    if (this.$route.params.id) {
      this.detailId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  getValue(provinceId: string | number, cityId: string | number, areaId: string | number, townId: string | number) {
    let valueData = {
      provinceId: provinceId,
      cityId: cityId,
      areaId: areaId,
      townId: townId,
    }
    return valueData
  }

  fetchData() {
    return api.externalMonthlyPlanService.audit;
  }

  // 获取详情
  async fetchDetail() {
    await api.externalMonthlyPlanService
      .get({id: this.detailId})
      .then(res => {
        let resData = res;
        this.getProjectDetail(res.project!.id);
        this.detail = {...resData};
        this.auditParams!.hostId = res.id + "";
        this.auditParams!.auditFlowId = res.auditFlowId;
        this.auditParams!.auditStatus = res.auditStatus;
        this.auditParams!.audit = res.audit;
        if (res.auditFlowId) {
          (this.auditButtons as any).fetchCanAudit();
        }
      });
  }

  getProjectDetail(projectId: any) {
    api.externalProject
      .getDetailByProjectId({projectId: projectId})
      .then(res => {
        this.externalProject = res;
        // this.goalList = res.effects!;
        // var other = this.goalList.push({ id: "", effect: "其他" });
        // //
        // this.specificPlanList = res.specificPlans!;
      });
  }

  changeAudit() {
    this.fetchDetail();
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
