
import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import {
  ExternalMonthlyPlanCreateOrUpdateDto,
  ExternalMonthlyPlanDetailCreateOrUpdateDto,
  ProjectSpecificGoalDto,
  ExternalProjectSpecificPlanDto,
  OrganizationUnitDto
} from "@/api/appService";
import api from "@/api";
import {ElForm} from "element-ui/types/form";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import ProvinceCityArea from "@/components/ProvinceCityArea/index.vue";

export interface SpecificPlanListDto {
  /**  */
  list?: ExternalProjectSpecificPlanDto[];

  /**  */
  goalId?: string | undefined;
}

@Component({
  name: "externalMonthlyPlanEdit",
  components: {
    ProjectSelect,
    ProvinceCityArea
  }
})
export default class externalMonthlyPlanEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  monthlyPlanList: ExternalMonthlyPlanDetailCreateOrUpdateDto[] = [
    {
      /**  */
      sequence: undefined,

      /** 具体计划Id */
      projectSpecificPlanId: undefined,

      /** 具体目标Id */
      projectSpecificGoalId: undefined,

      /** 标题 */
      title: undefined,

      /** 内容概述 */
      summary: undefined,

      /** 开始日期 */
      startDate: undefined,

      /** 结束日期 */
      endDate: undefined,

      /**  */
      provinceId: undefined,

      /**  */
      cityId: undefined,

      /**  */
      areaId: undefined,

      /**  */
      townId: undefined,

      /** 详细地址 */
      place: undefined,

      /** 预计人数 */
      peopleCount: undefined,

      /**  */
      id: 0
    }
  ];
  defaultData: ExternalMonthlyPlanCreateOrUpdateDto = {
    /** 日期 */
    date: undefined,

    /**  */
    projectId: undefined,

    /**  */
    details: this.monthlyPlanList,

    /**  */
    id: 0
  };
  goalList: ProjectSpecificGoalDto[] = [];
  specificPlanSelectList: SpecificPlanListDto[] = [];
  specificPlanList: ExternalProjectSpecificPlanDto[] = [];

  projectType = "";
  projectList = [];
  // 合作方机构列表
  partnerOrgList: OrganizationUnitDto[] = [];
  // 选择后合作方机构id
  partnerOrgIds = [];
  form: ExternalMonthlyPlanCreateOrUpdateDto = {...this.defaultData};

  // 添加一行
  private addRow() {
    let obj = {
      id: undefined,

      sequence: undefined,

      place: undefined,

      /**  */
      startDate: undefined,

      /**  */
      endDate: undefined,

      /**  */
      summary: undefined,

      /**  */
      partnerOrgId: undefined,

      /**  */
      needSupport: undefined,

      /** 摘要 */
      remark: undefined
    };
    this.form.details = [...this.form.details!, obj];
  }

  // 删除整行
  private deleteRow(index: number) {
    this.form.details!.splice(index, 1);
  }

  @Watch("form.projectId")
  initGoalList() {
    this.getSpecificGoalList();
  }

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
    }
  }

  getSpecificGoalList() {
    if (this.form.projectId == null || this.form.projectId <= 0) {
      this.goalList = [];
      this.specificPlanList = [];
      this.form.details = [];
      this.addRow();
    } else {
      api.externalProject
        .getDetailByProjectId({projectId: this.form.projectId})
        .then(res => {
          this.goalList = res.effects!.filter(item => item.effect);
          var other = this.goalList.push({id: "", effect: "其他"});
          //
          this.specificPlanList = res.specificPlans!.filter(item => item.title);
          //
        });
    }
  }

  initSpecificPlanList(row: ExternalMonthlyPlanDetailCreateOrUpdateDto) {
    var result = this.specificPlanList!.filter(function (
      item: ExternalProjectSpecificPlanDto
    ) {
      return item.projectSpecificGoalId == row.projectSpecificGoalId;
    });
    return result;
  }

  // 获取详情
  async fetchDetail() {
    await api.externalMonthlyPlanService.get({id: this.dataId}).then(res => {
      this.form = {...res};
      this.form.projectId = res.project!.id;
      this.form?.details!.forEach(s => {
        s.projectSpecificGoalId = s.projectSpecificGoalId ?? "";
      });
      this.getSpecificGoalList();
    });
  }

  cancel() {
    this.$router.go(-1);
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.externalMonthlyPlanService.update({
            body: {...this.form}
          }).then(() => {
            this.$message.success("更新成功");
            this.$router.go(-1);
          });
        } else {
          await api.externalMonthlyPlanService.create({
            body: {...this.form}
          }).then(() => {
            this.$message.success("新建成功");
            this.$router.go(-1);
          });
        }

      } else {
        this.$message({
          type: "error",
          message: "请检查表单"
        });
      }
    });
  }

  roleRule = {
    date: [
      {
        required: true,
        message: "请选择月份",
        trigger: "blur"
      }
    ],
    projectId: [
      {
        required: true,
        message: "请选择项目",
        trigger: "blur"
      }
    ]
  };
  detailRule = {
    startDate: [
      {
        required: true,
        message: "请选择开始日期",
        trigger: "blur"
      }
    ],
    endDate: [
      {
        required: true,
        message: "请选择结束日期",
        trigger: "blur"
      }
    ],
    townId: [
      {
        required: true,
        message: "请选择落地地区",
        trigger: "blur"
      }
    ],
    place: [
      {
        required: true,
        message: "请输入具体地点",
        trigger: "blur"
      }
    ],
    summary: [
      {
        required: true,
        message: "请输入内容概述",
        trigger: "blur"
      }
    ],
    peopleCount: [
      {
        required: true,
        message: "请填写预计人数",
        trigger: "blur"
      },
      {
        type: "number",
        message: "预计人数必须为数字",
        trigger: "blur"
      }
    ],
    projectSpecificGoalId: [
      {
        required: true,
        message: "请选择所属模块",
        trigger: "blur",
        validator: validUndefined
      }
    ],
    title: [
      {
        required: true,
        message: "请填写活动主题",
        trigger: "blur"
      }
    ],
    projectSpecificPlanId: [
      {
        required: true,
        message: "请填写活动主题",
        trigger: "blur"
      }
    ]
  };
}

function validUndefined(rule: any, value: string | undefined, callback: any) {
  if (value == undefined) callback(new Error("请选择所属模块"));
  else callback();
}
